<template>
    <div style="height: 100%; width: 100%; background-image: url('img/avatars/LoginBG.jpg'); background-size: cover; background-repeat: no-repeat; background-position: center; position: fixed;">
        <div style="padding-top:7%; margin-left: auto; margin-right: auto;">
            <div class="card" style="width:320px; margin-left: auto; margin-right: auto;">
                <form class="form-signin" style="width:100%; padding: 7%">
                    <img src="/img/avatars/Logo.jpg" style="width:275px" >
                    
                    <label for="inputUsername" class="sr-only">Username</label>
                    <div class="py-2">
                        <input type="text" id="inputUsername" class="form-control p-2" placeholder="Username" v-model="username" autofocus="">
                        <label id="errorUsername" class="form-error" style="display: none; float: left; padding-bottom: 0.5em;"></label>
                    </div>
                    <label for="inputPassword" class="sr-only">Password</label>
                    <div class="py-2">
                        <input type="password" id="inputPassword" class="form-control p-2" v-model="password" placeholder="Password">
                        <label id="errorPassword" class="form-error" style="display: none; float: left; padding-bottom: 0.5em;"></label>
                    </div>
                    <div class="py-2">
                        <input type="checkbox" @change="viewPassword"> 
                        <label class="px-1">View Password</label>
                    </div>
                    <div class="pb-2 text-center">
                        <label id="errorLogin" class="form-error" style="display: none; float: left; padding-bottom: 1em;"></label>
                    </div>
                    <button id="loginButton" class="btn btn-lg btn-primary btn-block" @click.prevent="handleSubmit()">Login</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { baseUrl } from '../../../infrastructure/constant/connection';
import loginServices from '../../Core/Account/Script/LoginServices.js';

export default {
    data(){
        return{
            username: localStorage.getItem('Name'),
            password: '',
            Error: 0,
        }
    },
    methods: {
        viewPassword(){
            var x = document.getElementById("inputPassword");
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        },
        rememberMe(){
            var name = document.getElementById('inputUsername').value;
            localStorage.setItem('Name', name);
        },
        handleSubmit(){
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if (this.username == ''){
                document.getElementById('errorLogin').style.display = 'block';
                document.getElementById('errorLogin').innerHTML = 'Username dan Password tidak boleh kosong';
                document.getElementById('errorLogin').style.color = 'red';
                this.Error++;
            }
            if (this.password == ''){
                document.getElementById('errorLogin').style.display = 'block';
                document.getElementById('errorLogin').innerHTML = 'Username dan Password tidak boleh kosong';
                document.getElementById('errorLogin').style.color = 'red';
                this.Error++;
            }
            
            if(this.Error == 0)
            {
                this.$loading(true);
                $('#loginButton').prop('disabled', true);
                this.$apollo.mutate({
                    mutation: loginServices.defaultquery(),
                    variables:{
                        username: this.username,
                        password: this.password
                    }
                }).then(res => {
                    this.$cookies.set('Token', res.data.LoginUser.token,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('Name', res.data.LoginUser.user_detail.myname,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('UserID', res.data.LoginUser.user_detail.userid,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('RoleName', res.data.LoginUser.user_detail.rname,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('RoleID', res.data.LoginUser.user_detail.roleid,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('ContactID', res.data.LoginUser.user_detail.contact_id,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('ContactName', res.data.LoginUser.user_detail.contact_name,new Date(res.data.LoginUser.expired_at*1000));
                    this.$cookies.set('TokenExpireTime', new Date(res.data.LoginUser.expired_at*1000),new Date(res.data.LoginUser.expired_at*1000));
                    window.location.href = baseUrl;
                }).catch(e => {
                    this.$loading(false);
                    var errorMessage = this.$globalfunc.ErrorExtractor(e)
                    document.getElementById('errorLogin').style.display = 'block';
                    document.getElementById('errorLogin').innerHTML = errorMessage;
                    document.getElementById('errorLogin').style.color = 'red';
                    $('#loginButton').prop('disabled', false);
                }).finally(() => {
                    this.$loading(false);
                    $('#loginButton').prop('disabled', false);
                });
            }
        },
        
    }
}
</script>
<style scoped>
</style>
