import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class LoginServices {
  defaultquery(){
      var query = gql`mutation($username:String!,$password:String!){
          LoginUser(username:$username,password:$password){
            token
            expired_at
            user_detail{
              myname
              userid
              roleid
              rname
              contact_id
              contact_name
            }
          }
      }`;

      return query;
  }

  changePasswordQuery(){
    var query = gql`mutation($username:String!,$password:String!){
        LoginUser(username:$username,password:$password){
          token
        }
    }`;

    return query;
  }
  
  async getUserQuery(id){
    const variables = {
        id : id
    }
    var query = gql`query($id:String!){
        GetUser(UserID:$id){
          userid
          username
          mypwd
          myname
          roleid
          rname
          active_flag
          last_login
          contact_id
          contact_name
          contact_type
          current_debit
          created_at
          last_update
        }
    }`;

    var result = await globalfunc.defaultApolloQueryDefault(query, variables);

    return result.data.GetUser[0];
  }

}

export default new LoginServices

